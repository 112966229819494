import { useCallback, useEffect, useRef, useState } from "react";
import { Fragment } from 'react';
import { Spinner, Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./session.css";
import *  as FDS from "@arctravel/react-fds/lib";
import { updateState } from "../../redux/payments";
import '../../index.css';
import { useOktaAuth } from "@okta/okta-react";
import { ErrorState, InputState } from "./FormState";
import HostedSessionForm from "./HostedSessionForm";
import ErrorModal from "../common/ErrorModal"
import { getMgpsApiHelperParams } from "../../helpers/mpgs-api-helper";
import TestOrProd from "../home/TestOrProd";
const axios = require('axios').default;



function HostedSession() {

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken;
    const groups = token?.claims.groups as string[];
    const isAdmin = groups && groups.some((g:string) => g === process.env.REACT_APP_ADMIN_ROLE);
    const paymentState = useAppSelector(state => state.payments);
    const {environment, merchant, path} = getMgpsApiHelperParams(isAdmin, paymentState);
   
    const MASTER_CARD_SESSION_JS_SRC = `https://${environment}.arcpay.travel/form/version/66/merchant/${merchant}/session.js`;
    const featureState = useAppSelector(state => state.features);
    const dispatch = useAppDispatch();
    const [paymentClicked, setPaymentClicked] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [values, setValues] = useState<InputState>({
        firstName: "",
        lastName: "",
        namePrefix: "",
        middleName: "",
        orderAmount: "",
        creditCardType: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        agencyInvoice: "",
        pnr: "",
        unit: "",
        associatedAirlineTicket: "",
        copyName: false
    });


    const history = useHistory();


    const orderRef = useRef<string>('');
    const validationRef = useRef<string>('');
    const sessionIdRef = useRef<string>('');
    const paymentClickedRef = useRef<string>('');
    validationRef.current = "true";

    const MPGS_TIMEOUT = 5000;


    const pay = () => {
        paymentClickedRef.current = "true";
        setPaymentClicked(true);
        validate({}, "orderAmount", values.orderAmount);
        validate({}, "firstName", values.firstName);
        validate({}, "lastName", values.lastName);
        validate({}, "middleName", values.middleName);
        validate({}, "namePrefix", values.namePrefix);
        validate({}, "creditCardType", values.creditCardType);
        validate({}, "associatedAirlineTicket", values.associatedAirlineTicket);
        validate({}, "agencyInvoice", values.agencyInvoice);
        validate({}, "pnr", values.pnr);
        validate({}, "chargeDescription", values.chargeDescription);
        validate({}, "address", values.address);
        validate({}, "unit", values.unit);
        validate({}, "city", values.city);
        validate({}, "state", values.state);
        validate({}, "zip", values.zip);    
    }

    const [initializing, setInitializing] = useState(true);



    const handleFormSessionUpdate = useCallback((response: any) => {
        // HANDLE RESPONSE FOR UPDATE SESSION
        if (response.status) {

            if (!response?.sourceOfFunds?.provided?.card?.nameOnCard || !response?.sourceOfFunds?.provided?.card?.securityCode) {
                if (!response?.sourceOfFunds?.provided?.card?.nameOnCard) {
                    (document.querySelector("#cardholder-name") as any).classList.add("is-invalid");
                }
                else {
                    (document.querySelector("#cardholder-name") as any).classList.remove("is-invalid");
                }

                if (!response?.sourceOfFunds?.provided?.card?.securityCode) {
                    (document.querySelector("#security-code") as any).classList.add("is-invalid");
                } else {
                    (document.querySelector("#security-code") as any).classList.remove("is-invalid");
                }

                paymentClickedRef.current = "false";
                window.scrollTo(0, 0);
            }
            else if (validationRef.current === "false") {
                paymentClickedRef.current = "false";
                window.scrollTo(0, 0);
            }
            else if ("ok" === response.status && validationRef.current === "true") {

                dispatch(updateState({ field: "orderId", value: orderRef.current }));
                dispatch(updateState({ field: "paymentErrorMessage", value: undefined }))

                if (response.airline && response.billing && paymentClickedRef.current === "true") {
                    if (featureState.include3DS) {
                        dispatch(updateState({ field: "needsAuth", value: "true" }));
                    }
                    else {
                        dispatch(updateState({ field: "needsPayment", value: "true" }));
                    }

                    const prevScript = document.querySelector(`script[src="${MASTER_CARD_SESSION_JS_SRC}"]`);

                    if (prevScript) {
                        prevScript.remove();
                    }
                    history.push(`/pay`);
                }
                else {
                    paymentClickedRef.current = "false";
                    window.scrollTo(0, 0);
                }

            } else if ("fields_in_error" === response.status) {
                console.log("Session update failed with field errors.");
                if (response.errors.cardNumber) {
                    console.log("Card number invalid or missing.");
                }
                if (response.errors.expiryYear) {
                    console.log("Expiry year invalid or missing.");
                }
                if (response.errors.expiryMonth) {
                    console.log("Expiry month invalid or missing.");
                }
                if (response.errors.securityCode) {
                    console.log("Security code invalid.");
                }
            } else if ("request_timeout" === response.status) {
                console.log("Session update failed with request timeout: " + response.errors.message);
            } else if ("system_error" === response.status) {
                console.log("Session update failed with system error: " + response.errors.message);
            }
        } else {
            console.log("Session update failed: " + response);
        }
    }, [MASTER_CARD_SESSION_JS_SRC, dispatch, featureState.include3DS, history])





    const [errors, setErrors] = useState<ErrorState>({

    });

    const updateSetBody = useCallback(() => {

        let id = orderRef.current || "";
        if (id?.trim().length === 0) {
            id = uuidv4();
            orderRef.current = id;
        }


        const body = {} as any;
        body.billing = {};
        body.billing.address = {};
        body.billing.address.street = values.address;
        body.billing.address.city = values.city;
        body.billing.address.stateProvince = values.state;
        body.billing.address.postcodeZip = values.zip;

        if (values.unit) {
            body.billing.address.street2 = values.unit;
        }

        body.airline = {};
        body.airline.passenger = [];
        const passenger: any = {};
        passenger.firstName = values.firstName;
        passenger.lastName = values.lastName;

        if (values.middleName) {
            passenger.middleName = values.middleName;
        }

        if (values.namePrefix) {
            passenger.title = values.namePrefix;
        }

        body.airline.passenger.push(passenger);

        if (values.pnr) {
            body.airline.bookingReference = values.pnr;
        }

        body.airline.ticket = {};
        body.airline.ticket.issue =  {
            "travelAgentCode": "49560744",
            "travelAgentName": "ARCTRAVELOFFICE"
        };

        if (values.associatedAirlineTicket) {       
            body.airline.ticket.ticketNumber = values.associatedAirlineTicket;
        }

        body.order = {};
        body.order.id = orderRef.current;
        body.order.amount = values.orderAmount!.replace(/,/g, "").replace(/\$/g, "").trim();;
        body.order.currency = "USD";
        body.order.reference = uuidv4();

        if (values.chargeDescription) {
            body.order.description = values.chargeDescription;
        }

        if (values.agencyInvoice) {
            body.order.invoiceNumber = values.agencyInvoice;
        }
        return body;

    }, [values.address, values.agencyInvoice, values.associatedAirlineTicket, values.chargeDescription, values.city, values.firstName, values.lastName, values.middleName, values.namePrefix, values.orderAmount, values.pnr, values.state, values.unit, values.zip])

    useEffect(() => {
        if (paymentClicked) {
            const { PaymentSession } = (window as any);

            if (!PaymentSession) {
                return;
            }

            PaymentSession.validate("card", function (result: any) {
                // HANDLE VALIDATION RESULTS

                let mpgsFieldsValid = true;
                if (result.card) {
                    if (result.card.number.hasError) {
                        (document.querySelector(result.card.number.selector) as any).classList.add("is-invalid");
                        validationRef.current = "false";
                        mpgsFieldsValid = false;
                    }

                    if (result.card.securityCode.hasError) {
                        (document.querySelector(result.card.securityCode.selector) as any).classList.add("is-invalid");
                        validationRef.current = "false";
                        mpgsFieldsValid = false;
                    }

                    if (result.card.expiryMonth.hasError) {
                        (document.querySelector(result.card.expiryMonth.selector) as any).classList.add("is-invalid");
                        validationRef.current = "false";
                        mpgsFieldsValid = false;

                    }

                    if (result.card.expiryYear.hasError) {
                        (document.querySelector(result.card.expiryYear.selector) as any).classList.add("is-invalid");
                        validationRef.current = "false";
                        mpgsFieldsValid = false;
                    }

                    if (result.card.nameOnCard.hasError) {
                        (document.querySelector(result.card.nameOnCard.selector) as any).classList.add("is-invalid");
                        validationRef.current = "false";
                        mpgsFieldsValid = false;
                    }
                }

                if (hasError) {
                    validationRef.current = "false"
                }

                if (!hasError && mpgsFieldsValid) {

                    validationRef.current = "true";
                    const body: any = updateSetBody();
                    const token = oktaAuth.getAccessToken();
                    axios.put(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/update-session/${sessionIdRef.current}`, body,
                        {
                            headers: {
                                Authorization: "Bearer " + token,
                            }
                        }).then((res: any) => {
                            PaymentSession.updateSessionFromForm('card');
                        });
                }
                else {
                    PaymentSession.updateSessionFromForm('card');
                }

                setPaymentClicked(false);
            });
        }


    }, [paymentClicked, hasError, updateSetBody, oktaAuth, sessionIdRef, path]);


    useEffect(() =>
    {
        setHasError(   
            (errors.number ||
            errors.nameOnCard ||
            errors.securityCode ||
            errors.expiryYear ||
            errors.expiryMonth ||
            errors.orderAmount ||
            errors.firstName ||
            errors.lastName ||
            errors.middleName ||
            errors.namePrefix ||
            errors.associatedAirlineTicket ||
            errors.agencyInvoice ||
            errors.pnr ||
            errors.chargeDescription ||
            errors.creditCardType ||
            errors.address ||
            errors.unit ||
            errors.city ||
            errors.state ||
            errors.zip) ? true : false);
    }, [errors])


    const validate = (event: any, name: any, value: any) => {
        //A function to validate each input values

        switch (name) {
            case 'namePrefix':
                if (value?.trim()?.length === 0 || !value) {

                    setErrors(prev => {
                        return { ...prev, namePrefix: name + " Required" }
                    })
                }
                else {
                    if (errors.namePrefix) {
                        setErrors(prev => {
                            return { ...prev, namePrefix: undefined }
                        })
                    }
                }
                break;
            case "state":
                if (value?.trim()?.length === 0 || !value) {

                    setErrors(prev => {
                        return { ...prev, state: name + " Required" }
                    })
                }
                else {
                    if (errors.state) {
                        setErrors(prev => {
                            return { ...prev, state: undefined }
                        })
                    }
                }
                break;
            case 'creditCardType':

                if (value?.trim()?.length === 0 || !value) {

                    setErrors(prev => {
                        return { ...prev, creditCardType: name + " Required" }
                    })
                }
                else {
                    if (errors.creditCardType) {
                        setErrors(prev => {
                            return { ...prev, creditCardType: undefined }
                        })
                    }
                }
                break;
            case 'associatedAirlineTicket':
                if ((value?.length > 0 && value?.length < 11) || value?.length > 16) {
                    setErrors(prev => { return { ...prev, associatedAirlineTicket: "Associated Airline Ticket must be between 11 and 16 characters in length." } });
                }
                else {
                    if (errors.associatedAirlineTicket) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                associatedAirlineTicket: undefined
                            }
                        })
                    }
                }
                break;
            case 'lastName':
                if (value?.trim().length < 1 || value?.length > 20) {
                    setErrors(prev => { return { ...prev, lastName: "Last Name must be between 1 and 20 characters in length." } });
                }
                else {
                    if (errors.lastName) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                lastName: undefined
                            }
                        })
                    }
                }
                break;
            case 'firstName':
                if (value?.trim().length < 1 || value?.length > 50) {
                    setErrors(prev => { return { ...prev, firstName: "First Name must be between 1 and 50 characters in length." } });
                }
                else {
                    if (errors.firstName) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                firstName: undefined
                            }
                        })
                    }
                }
                break;
            case 'city':
                if (value?.trim().length < 1 || value?.length > 100) {
                    setErrors(prev => { return { ...prev, city: "City must be between 1 and 100 characters in length." } });
                }
                else {
                    if (errors.city) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                city: undefined
                            }
                        })
                    }
                }
                break;
            case 'address':
                if (value?.trim().length < 1 || value?.length > 100) {
                    setErrors(prev => { return { ...prev, address: "Address must be between 1 and 100 characters in length." } });
                }
                else {
                    if (errors.address) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                address: undefined
                            }
                        })
                    }
                }
                break;
            case 'agencyInvoice':
                if (value?.length > 25) {
                    setErrors(prev => { return { ...prev, agencyInvoice: "Agency Invoice Number must be between 1 and 25 characters in length." } });
                }
                else {
                    if (errors.agencyInvoice) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                agencyInvoice: undefined
                            }
                        })
                    }
                }
                break;
            case 'pnr':
                if ((value?.length > 0 && value?.length < 6) || (value?.length > 15)) {
                    setErrors(prev => { return { ...prev, pnr: "PNR / Airline Booking Reference must be between 6 and 15 characters in length." } });
                }
                else {

                    if (errors.pnr) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                pnr: undefined
                            }
                        })
                    }
                }
                break;
            case 'unit':
                if (value && value?.length > 6) {
                    setErrors(prev => { return { ...prev, unit: "Unit must be between 1 and 6 characters in length." } });
                }
                else {

                    if (errors.unit) {
                        setErrors(prev => {
                            return {
                                ...prev,

                                unit: undefined
                            }
                        })
                    }
                }
                break;
            case 'zip':
                if (value?.length < 1) {
                    setErrors(prev => { return { ...prev, zip: "Zip Code is required." } });
                }
                else if (value?.length > 5) {
                    setErrors(prev => { return { ...prev, zip: "Zip Code must be between 1 and 5 characters in length." } });
                }
                else {
                    if (errors.zip) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                zip: undefined
                            }
                        })
                    }
                }
                break;
            case 'chargeDescription':
                if (value?.length && value?.length > 127) {
                    setErrors(prev => { return { ...prev, chargeDescription: "Charge Description must be between 1 and 127 characters in length." } });
                }
                else {
                    if (errors.chargeDescription) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                chargeDescription: undefined
                            }
                        })
                    }
                }
                break;
            case 'orderAmount':
                if (value?.trim()?.length === 0 || !value) {
                    setErrors(prev => {
                        return {
                            ...prev,
                            orderAmount: "Transaction Amount is required."
                        }
                    })
                }
                else if (parseFloat(value.replace(/\$/g, '').replace(/,/g).trim()) === 0.00) {
                    setErrors(prev => {
                        return {
                            ...prev,
                            orderAmount: "Transaction Amount must be greater than zero."
                        }
                    })
                }
                else {
                    if (errors.orderAmount) {
                        setErrors(prev => {
                            return {
                                ...prev,
                                orderAmount: undefined
                            }
                        })
                    }
                }
                break;
        }
    }

    const formatCurrency = () => {

        if (!values.orderAmount) {
            setErrors(prev => { return { ...prev, orderAmount: "Transaction Amount must be greater than zero." } });
            setValues(prev => { return { ...prev, orderAmount: "$0.00" } });
            return;
        }

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const formattedValue = formatter.format(parseFloat(values.orderAmount!.replace(/,/g, "").replace(/\$/g, "").trim()));
        if (formattedValue !== "NaN") {
            setValues(prev => { return { ...prev, orderAmount: formattedValue } });
        } else {
            setErrors(prev => { return { ...prev, orderAmount: "Please enter a valid Transaction Amount." } });
            setValues(prev => { return { ...prev, orderAmount: "$0.00" } });
        }

    }

    const handleChange = (e: any) => {
        let name = e.target.id;
        let value = e.target.value;
        if (name === "orderAmount") {
            if (!value || value?.match(/^[$0-9,]+(\.\d{0,2})?$/)) {
                setValues(prev => { return { ...prev, [name]: value } });
            }
        }
        else if (name === "zip") {

            if (!value || value.match(/^[0-9\b]+$/)) {
                (values as any)[name] = value;
                setValues(prev => { return { ...prev, [name]: value } });
            }
        } else {
            setValues(prev => { return { ...prev, [name]: value } });
        }


        validate(e, name, value);
    }


    let setMiddleInitial = () => {
        if (values.middleName && values.middleName?.trim().length > 1) {
            const obj: any = {};
            obj.middleName = values.middleName.trim().substr(0, 1);
            setValues({ ...values, ...obj });
        }
    }

    useEffect(() => {
        const onScriptLoad = ({
            initialized,
            formSessionUpdate,
            sessionId
        }: any) => {
            const { PaymentSession } = (window as any);

            if (!PaymentSession) {
                return;
            }


            PaymentSession.configure({
                session: sessionId,
                fields: {
                    card: {
                        number: "#card-number",
                        securityCode: "#security-code",
                        expiryMonth: "#expiry-month",
                        expiryYear: "#expiry-year",
                        nameOnCard: "#cardholder-name",
                    },
                },
                frameEmbeddingMitigation: ["javascript"],
                interaction: {
                    displayControl: {
                        formatCard: "EMBOSSED",
                        invalidFieldCharacters: "REJECT"
                    }
                },
                callbacks: {
                    initialized: (response: any) => {
                        initialized(response);
                    },
                    formSessionUpdate: (response: any) => {
                        formSessionUpdate(response);
                    },
                },
            });

            PaymentSession.onCardTypeChange(function (selector: any, result: any) {
                //handle change event
                if (result.status === 'SUPPORTED') {


                    if (result.scheme === "MASTERCARD") {
                        setValues(prev => { return { ...prev, creditCardType: "Mastercard" } });
                        setErrors(prev => { return { ...prev, creditCardType: undefined } });
                    }
                    else if (result.scheme === "VISA") {
                        setValues(prev => { return { ...prev, creditCardType: "Visa" } });
                        setErrors(prev => { return { ...prev, creditCardType: undefined } });
                    }
                    else if (result.scheme === "AMEX") {
                        setValues(prev => { return { ...prev, creditCardType: "American Express" } });
                        setErrors(prev => { return { ...prev, creditCardType: undefined } });
                    }
                } else if (result.status === 'INSUFFICIENT_LENGTH') {
                    // selector for invalid field is provided
                    console.log('Minimum of 10 digits required to determine card type.');
                } else if (result.status === 'NOT_SUPPORTED') {
                    console.log("card is not supported");
                    //setErrors(prev => { return { ...prev, creditCardNumber: "The card type you entered is not supported." }});
                }
            });
            PaymentSession.onValidityChange(["card.number", "card.nameOnCard", "card.securityCode", "card.expiryYear", "card.expiryMonth"], function (selector: any, result: any) {
                if (result.isValid === false) {
                    document.querySelector(selector).classList.add("is-invalid");
                }
                else {
                    document.querySelector(selector).classList.remove("is-invalid");
                }

            });

            PaymentSession.onEmptinessChange(["card.number", "card.nameOnCard", "card.securityCode", "card.expiryYear", "card.expiryMonth"], function (selector: any, result: any) {

                if (result.isEmpty) {
                    document.querySelector(selector).classList.add("is-invalid");
                }
            });

            PaymentSession.onBlur(["card.number", "card.nameOnCard", "card.securityCode", "card.expiryYear", "card.expiryMonth"],
                function (selector: any, role: any) {
                    PaymentSession.validate('card', function (allresult: any) {

                        const r = ["nameOnCard", "number", "securityCode", "expiryYear", "expiryMonth"].find((ro: string) => ro === role);
                        if (r !== undefined)
                        {
                            if (allresult.card[r].isValid) {
                                document.querySelector(selector).classList.remove("is-invalid");

                            } else {
                                document.querySelector(selector).classList.add("is-invalid");
                            }
                        }
                    });
                });
        };


        if (!sessionIdRef.current) {

            const loadScript = async (formSessionUpdate: any) => {
                if (!document) {
                    return Promise.reject();
                }

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject();
                    }, MPGS_TIMEOUT);

                    const prevScript = document.querySelector(`script[src="${MASTER_CARD_SESSION_JS_SRC}"]`);

                    if (prevScript) {
                        prevScript.remove();
                    }

                    const script = document.createElement('script');
                    script.src = MASTER_CARD_SESSION_JS_SRC;
                    script.onerror = reject;
                    script.onload = () => onScriptLoad({
                        initialized: resolve,
                        formSessionUpdate,
                        sessionId: sessionIdRef.current
                    });

                    document.body.appendChild(script);
                });
            }

            dispatch(updateState({ field: "transactionId", value: "" }));
            dispatch(updateState({ field: "orderId", value: "" }));
            if (!error) {
                const token = oktaAuth.getAccessToken();
                axios.post(`${process.env.REACT_APP_MPGS_BASE_URL}${path}/create-session`, {},
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        }
                    }
                ).then((res: any) => {
                    sessionIdRef.current = res.data.session.id;
                    dispatch(updateState({ field: "sessionId", value: res.data.session.id }));
                    //sessionStorage.setItem('demo-session', JSON.stringify(res.data));
                    loadScript(handleFormSessionUpdate)
                        .then(() => setInitializing(false))
                        .catch(() => {
                            setError(true);
                            setShowErrorModal(true);
                        });
                }).catch((exResp: any) => {
                    setError(true);
                    setShowErrorModal(true);
                });
            }
        }



    }, [handleFormSessionUpdate, error, history, dispatch, oktaAuth, sessionIdRef, MASTER_CARD_SESSION_JS_SRC, path]);


    return <Fragment>
        
        <FDS.FDSLevel mainTitle="ARC Pay API Demo" subTitle="Hosted Session" type="two">

            <FDS.FDSLevel.Centered>
                <TestOrProd />
                <Container style={{ maxWidth: "600px" }}>
                    <Row>

                        <Col lg="12">
                            <div>
                                {initializing && (
                                    <div style={{ height: "500px", width: "100%", lineHeight: "500px", textAlign: "center" }}>
                                        <div style={{ width: "300px", height: "100px", lineHeight: "initial", verticalAlign: "middle", display: "inline-block" }}>
                                            <Spinner animation="border" style={{ fontSize: "16px" }} role="status" variant="primary">
                                            </Spinner>
                                        </div>
                                    </div>
                                )}
                                <ErrorModal showErrorModal={showErrorModal} setShowErrorModal={setShowErrorModal} />
                                {(<HostedSessionForm validate={validate} sessionId={sessionIdRef.current} formatCurrency={formatCurrency} initializing={initializing} pay={pay} setMiddleInitial={setMiddleInitial} values={values} errors={errors} handleChange={handleChange} />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </FDS.FDSLevel.Centered>
            <div></div>
        </FDS.FDSLevel>
    </Fragment>
}

export default HostedSession;